import { h } from 'preact';
import { connect } from 'redux-zero/react';
import { useEffect } from 'preact/hooks';

import { route } from '../../helpers/router';

import { unsetTempSrs, setTempSrs } from './srs/actions';

import Loader from '../../components/common/loader';
import SeatOption from './seatOption';
import EventGuard from '../../components/eventGuard';

const FindMe = (props) => {
  const {
    seatType,
    savedSrsType,
    clearTempSrs,
    setSeatType,
  } = props;
  if (!seatType) {
    return <Loader />;
  }

  useEffect(clearTempSrs, []);

  const enabledSeatTypes = Object.keys(seatType).filter(type => seatType[type].enabled);

  // TODO handle single seat type redirect after adding the SRS pages
  if (enabledSeatTypes.length === 0) {
    return route('/findme/landmark');
  }

  if (enabledSeatTypes.length === 1) {
    setSeatType({ type: enabledSeatTypes[0] });
    return route('/findme/srs/section');
  }

  return <SeatOption seatTypes={enabledSeatTypes} savedSrsType={savedSrsType} />;
};

const ConnectedComponent = connect(
  ({
    event,
    srs: {
      type,
    },
  }) => ({
    savedSrsType: type,
    seatType: event.seatType,
  }),
  () => ({ setSeatType: setTempSrs, clearTempSrs: unsetTempSrs }),
)(FindMe);

export default props => (
  <EventGuard>
    <ConnectedComponent {...props} />
  </EventGuard>
);
